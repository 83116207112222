import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query getUsers($id: ID!) {
    getUsers(id: $id) {
      user_id
      customer_id
      email
      alias
    }
  }
`;

export const CREATE_NEW_USER = gql`
  mutation createNewUser($data: userInput!) {
    createNewUser(data: $data) {
      customer_id
      user_id
      email
      alias
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($user_id: ID!) {
    deleteUser(user_id: $user_id)
  }
`;

export const IS_MAIL_VALID = gql`
  query isMailValid($email: String!) {
    isEmailValid(email: $email)
  }
`;

export const CHANGE_EMAIL = gql`
  mutation admin($customer_id: ID!, $email: String!) {
    updateCustomerEmail(customer_id: $customer_id, email: $email)
  }
`;
